@import url('https://fonts.googleapis.com/css?family=Montserrat+Alternates:700|Montserrat:700|Muli:400,800&display=swap');

.placeholder{
  background-color: #DDDDDD;
  height: 100px;
}

$green: #83BF37;
$darker-green: #537d2e;
$black: #222222;
$light-green: #c7ed98;

$narrow-margin: 12px;
$small-margin: 20px;
$medium-margin: 40px;
$large-margin: 80px;

$button-font-size: 19px;
$small-font-size: 15px;
$paragraph-font-size: 18px;

$button-large: 205px;
$button-xlarge: 245px;
$button-xxlarge: 284px;

@mixin montserrat-bold{
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

@mixin montserrat-alt{
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: 700;
}

@mixin muli{
  font-family: 'Muli', sans-serif;
  font-weight: 400;
}

body{
  @include muli;
}

h1, h2, h3, h4, h5, h6, button, .button{
  @include montserrat-bold;
}

.app-container{
  border-top: 28px solid $black;

  &.app-container-search{
    background-color: #EEEEEE;
    min-height: 800px;
  }
}

.container{
  
}

.section{
  padding: 0;
}

/* HEADER */

.header{
  border-bottom: 6px solid $black;
}

.header,
.footer,
.question{
  display: flex;
  flex-flow: row nowrap; /* desktop */
}

.footer{
  padding: 15px 0 45px;
  background-color: transparent;
  border-top: 6px solid $black;
  margin: $large-margin 0 0;

  p{
    font-weight: 700;

    a{
      border-bottom: none;
    }
  }
}

.header-left,
.footer-left,
.question-left{
  // flex: 0 0 37%;
  flex: 0 0 33.8%;
}

.header-right,
.footer-right,
.question-right{
  // flex: 0 0 63%;
  flex: 0 0 66.2%;
}

/* HEADER */

.header-left{
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.header-right{
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 24px;
}

/* RULES */

.hr-thick{
  border-bottom: 6px solid $black;
}

.hr-thin{
  border-bottom: 1px solid $black;
}

/* SPACING */

p + .hr{
  margin-top: $large-margin;
}

.button + p,
button + p{
  margin-top: $large-margin;
}

/* LOGO */

a.logo-text{
  @include montserrat-alt;
  line-height: 1;
  padding: $medium-margin+10px 0 $small-margin+10px;
  font-size: 83px;
  border-bottom: none;
  transform: translateX(-3.5px);

  &:hover{
    border-bottom: none;
    color: $green;
  }

  &:active{
    border-bottom: none;
    color: $green;
  }

  &:focus{
    border-bottom: none;
    color: $green;

  }
}

.logo-graphic{
  img{
    width: 118px;
  }
}

/* NAV */
.tabs.is-toggle{

  ul{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  li{
    flex: 0 0 15.5%;

    &:first-child a{
      border-radius: 0;
    }

    &:last-child a{
      border-radius: 0;
    }

    &.is-active{
      a{
        border-bottom: 6px solid $black;
        background-color: transparent;
        color: $black;
        padding-top: 13px;
        
        &:hover{
          padding-top: 13px;
          background-color: transparent;
          border-bottom: 6px solid $green;
        }
      }
    }

    a{
      border: 0;
      border-bottom: 1px solid $black;
      border-radius: none;
      height: 40px;
      font-size: $small-font-size;
      font-weight: 700;
      line-height: 1;

      &:hover{
        padding-top: 13px;
        background-color: transparent;
        border-bottom: 6px solid $green;
      }
    }
  }
}

/* QUESTION */

.question{
  padding-top: $medium-margin;
}

.question-left{
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
}

.question-right{

  .columns{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
  }
  
  .column{
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: row nowrap; /* desktop */
    align-items: stretch;
  }

  /* hacky */

  .fancy-checkboxes-summary{
    .column{
      flex: 0 0 100%;
    }
  }

  .column.is-half{
    flex: 0 0 49%;
  }
  
  .column.is-one-third{
    flex: 0 0 31.95%;
  }

  .button{
    width: $button-large;
    margin-top: $large-margin;

    @media only screen and (min-width: 1216px){
      width: $button-xlarge;
    }
    
    @media only screen and (min-width: 1408px){
      width: $button-xxlarge;
    }
  }
}

/* BUTTON */

.button{
  font-size: $button-font-size;
  border-radius: 0;
  border-width: 3px;
  line-height: 1;
  border-color: $green;
}

.button:hover{
  border-color: $green;
  background-color: $green;
}

.button:focus:not(:active), 
.button.is-focused:not(:active){
  color: $darker-green;
  border-color: $green;
  box-shadow: 0 0 0 4px rgba(131, 191,55, 0.25);
}

button + button,
.button + .button{
  margin-left: $narrow-margin;

  @media only screen and (min-width: 1216px){
    margin-left: $narrow-margin+3px; /* HACKY */
  }
  
  @media only screen and (min-width: 1408px){
    margin-left: $narrow-margin+5px; /* HACKY */
  }
}

/* BUTTON GREY */

.button-grey{
  border-color: #CCCCCC;
}

.button-grey:hover{
  border-color: #CCCCCC;
  background-color: #CCCCCC;
}

.button-grey:focus:not(:active), 
.button-grey.is-focused:not(:active){
  color: $black;
  border-color: #888888;
  box-shadow: 0 0 0 4px rgba(0,0,0, 0.10);
}

/* TYPOGRAPHY */

h1,h2,h3,h4,h5,h6,p,ul,li,ol,a{
  padding: 0;
  margin: 0;
  color: $black;
}

h1{
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-size: 36px;
  margin-bottom: $medium-margin;
}

h1{
  border-top: 6px solid $black;
  margin-top: $medium-margin;
  padding-top: $medium-margin;

  &:first-of-type{
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }
}

h2{
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-size: 24px;
}

p{
  font-size: $paragraph-font-size;
}

a{
  border-bottom: 4px solid #EEEEEE;
  padding-bottom: 2px;
  color: $black;
}

a:hover{
  color: $darker-green;
  border-bottom: 4px solid rgba(131, 191,55, 0.25);
}

a:focus{
  color: $darker-green;
  outline: none;
  border-bottom: 4px solid $green;
}

.text-button{
  @include muli;
  font-size: inherit;
  font-weight: 700;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin-left: 2px;
  border-bottom: 4px solid #EEEEEE;

  &:hover{
    color: $darker-green;
    border-bottom: 4px solid rgba(131, 191,55, 0.25);
  }
  
  &:focus{
    color: $darker-green;
    outline: none;
    border-bottom: 4px solid $green;
  }
}

// messy

.speech-button{
  margin-top: $small-margin*0.75;
}

.speech-button-text{
  display: inline-block;
  color: $black;
  font-weight: 700;
  cursor: pointer;
  padding: 2px 8px;
  border: 2px solid #CCCCCC;

  &:hover{
    color: $darker-green;
    border-color: $green;
  }
  
  &:focus{
    outline: none;
    color: $darker-green;
    border-color: $green;
    box-shadow: 0 0 0 4px rgba(131, 191,55, 0.25);
  } 

  .is-active &,
  &:active,
  &.active{
    outline: none;
    color: $black;
    background-color: $green;
    border-color: $green;
  } 
}

// .speech-button-text{
//   color: $black;
//   font-weight: 700;
//   cursor: pointer;
//   padding-bottom: 2px;
//   border-bottom: 4px solid #EEEEEE;

//   &:hover{
//     color: $darker-green;
//     border-bottom: 4px solid rgba(131, 191,55, 0.25);
//   }
  
//   &:focus{
//     color: $darker-green;
//     outline: none;
//     border-bottom: 4px solid $green;
//   } 
// }

// messy
.DEBUG-ONLY{
  margin-top: $small-margin;

  span{
    background-color: $light-green;
    padding: $small-margin*0.5;
    display: block;
    color: $darker-green;
    font-weight: 700;
    font-size: $button-font-size;
  }
}

p.intro{
  font-size: $button-font-size;
  font-weight: 700;
}

p.small{
  font-size: $small-font-size;

  .question-right &{
    margin-bottom: $small-margin;
  }
}

/* HELP TEXT */

.help-text{
  margin-right: 28px;
  margin-left: auto;
  margin-top: 8px;
  border-top: 6px solid $green;
  padding-top: 10px;
  width: $button-large;

  @media only screen and (min-width: 1216px){
    width: $button-xlarge;
  }
  
  @media only screen and (min-width: 1408px){
    width: $button-xxlarge;
  }
}

.help-text-button{
  margin-left: 3px;
  cursor: pointer;

  &.help-text-button-off {

    .far{
      display: block;

      &:hover{
        color: $green;
      }
    }

    .fas{
      display: none;
    }
  }

  &.help-text-button-on {

    .far{
      display: none;
    }

    .fas{
      display: block;
      color: $green;

      &:hover{
        color: $black;
      }
    }
  }
}

i.help{
  font-size: 0.65em;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  transform: translateY(-1px);
}

/* CHECKBOXES */

.fancy-checkboxes{
  margin-top: $small-margin*0.5;

  &.fancy-checkboxes-summary{
    display: flex;
    flex-flow: row wrap;
  }
}

.fancy-checkbox{
  border: 1px solid $black;
  border-bottom: 6px solid $black;
  width: 100%;
  display: flex;
  flex-flow: column nowrap; /* desktop */
  cursor: pointer;
  margin-bottom: $small-margin*0.75;

  &:hover{
    border-color: $green;
    border-width: 4px;

    .fancy-checkbox-text{
      border-top: 1px solid $green;
    }
  }

  &:focus-within{
    border-color: $green;
    border-width: 4px;
    box-shadow: 0 0 0 4px rgba(131, 191,55, 0.25);

    .fancy-checkbox-text{
      border-top: 1px solid $green;
    }
  }

  &.fancy-checkbox-checked{
    border-color: $green;
    border-width: 4px;

    .fancy-checkbox-text{
      border-top: 1px solid $green;
    }
  }
}

.fancy-checkbox-image{
  text-align: center;
  padding: $small-margin;
  background-color: #EEEEEE;
  
  i{
    font-size: 60px;
  }
}

.fancy-checkbox-text{
  border-top: 1px solid $black;
  padding: $small-margin*0.75 $small-margin;
  display: flex;
  flex-flow: row nowrap;
  
  input{
    margin-top: 7px;
    margin-right: 8px;
  }

  p{
    font-weight: 700;
    line-height: 1.2;
    padding-left: 5px;
  }
}

/* RADIO */

.dimensions-group,
.checkbox-group,
.radio-group{
  margin-top: $small-margin*0.5;
}

.checkbox-simple,
.radio{
  display: flex;
  flex-flow: row nowrap;
  
  input[type='radio']{
    margin-right: 10px;
    margin-top: 11px;
  }

  input[type='checkbox']{
    margin-right: 10px;
    margin-top: 13px;
  }

  p{
    line-height: 2;
  }
}


.radio + .radio{
  margin-left: 0;
}

input[type='checkbox']{
  width: 10px;
  height: 10px;
}

.field{
  font-size: $paragraph-font-size;

  .label{
    text-align: left;
    font-weight: 400;
  }

  input{
    font-size: $paragraph-font-size;
  }
}

.mobility-aid-image{
  width: 100%;
  height: auto;
  display: block;
  margin: 8px 40px  $small-margin 0;
}

.summary-actions{
  .button,
  button{
    display: block;
    width: 100%;

    + .button,
    + button{
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

.summary-actions-title{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.summary-email-print{
  .button,
  button{
    width: auto;
    margin: 0;
    padding: 0;
    margin: 0;
    font-size: 0.85em;
    line-height: 1;
    height: auto;
    border: 0;
    height: 46px;
    margin-left: 20px;
    display: inline-block;

    &:hover,
    &:focus,
    &:active{
      color: $green;
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }
  }

  span{
    display: none;
  }
}

.summary-actions-buttons{
  .button,
  button{
    margin-top: $medium-margin;
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
    min-width: 250px;

    i{
      display: inline-block;
      margin-right: 5px;
    }
  }
}

.search{
  padding-left: 0;
  padding-right: 0;

  input{
    font-size: $paragraph-font-size;
  }
}

.box-search{
  display: flex;
  flex-flow: row nowrap;
}

.box-search-left{
  flex: 1 0 50%;
  padding-right: 30px;
}

.box-search-right{
  flex: 0 0 30%;
}

.box-search-place{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.box-search-to{
  flex: 0 0 48%;
}

.box-search-from{
  flex: 0 0 48%;
}

/* MESSY */

.box-search-dates{
  margin-top: $narrow-margin;

  .date-fields{
    width: 100%;

    .field{
      .control{
        .DateRangePicker{
          width: 100%;

          > div{

            > div{
              width: 100%;
            }
          }
        }
      }
    }
  }
}

h1:first-of-type.search-flights{
  margin-top: $small-margin;
  margin-bottom: $small-margin;
}

.search-results{
  margin-top: $small-margin;
}

.search-result{
  margin-bottom: $small-margin;
  padding: 0;

  .segments{

  }

  .segment{
    padding: $small-margin;

    + .segment{
      border-top: 1px solid #CCC;
    }

    
    &.a11y-match{
      border: 7px solid $green;
      
      &:first-of-type{
        border-bottom: 0;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
  
      &:last-of-type{
        border-top: 1px solid $green;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}

.level{
  margin-top: $small-margin;

  button,
  .button{
    border: 0;
    background-color: transparent;
    font-weight: 400;
    font-size: 16px;

    span{
      font-weight: 400;
    }
  }
}
